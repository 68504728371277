import React from 'react'
import { navigate } from 'gatsby'
import AlertMessagge from '../alertMessagge/alertMessagge'
import NotFound from '../../../shared/assets/images/404.svg'
import Button from '../../../shared/components/atoms/Button/Button'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import './notFoundContent.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const NotFoundContent = () => {
	const { isBrowser } = useBrowserMode()
	return (
		<section className='f-notfound-wrapper'>
			<AlertMessagge
				icon={<img src={NotFound} alt='Not found' />}
				messagge='PÁGINA NO ENCONTRADA'
				description='Lo sentimos, la página que buscabas no se encuentra disponible en este momento.'
				type='primary'
				button={
					<Button
						type='button'
						onClickAction={() => isBrowser && navigate('/')}
						classname='f-button f-button--primary'
						text={convertText('IR AL INICIO')}
					/>
				}
			/>
		</section>
	)
}

export default NotFoundContent
